import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Interface
import { TextProps } from 'components/flex/Text/Shell'
import Plaatjie from '@ubo/plaatjie'

const Image = styled(Plaatjie)`
  height: 100%;
  width: 90%;
  @media (max-width: 1349px) {
    width: 90%;
  }
  @media (max-width: 991px) {
    width: 60%;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 55px;
      margin-bottom: 2rem;
    }
  }
`

const TextImage: React.FC<TextProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-7 d-flex justify-content-center">
          <Image image={fields.image} alt="" className="mb-5 mb-md-0" />
        </div>
        <div className="col-md-5">
          <Content content={fields.description} />
        </div>
      </div>
    </div>
  </section>
)

export default TextImage
