/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Third Party
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const StyledThanks = styled.div``

const Step = styled.div`
  color: ${({ theme }) => theme.color.dark} !important;
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.2s ease-in-out;
  will-change: transform;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  min-height: 210px;

  & span {
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 16px;
  }

  &:hover {
    filter: drop-shadow(5px 5px 5px #ffffff);
  }
`

const Icon = styled(Plaatjie)`
  width: 50px;
  height: 50px;
`

interface ThanksProps {}

const ThanksVacancy: React.FC<ThanksProps> = () => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.thanksVacancyQueryQuery>(graphql`
    query thanksVacancyQuery {
      wpComponent(databaseId: { eq: 653 }) {
        thanks {
          description
          process {
            description
            icon {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <StyledThanks>
      <div className="row">
        <ParseContent content={fields.thanks.description} className="mb-5" />
        {fields.thanks.process.map((step: any, index: number) => (
          <div key={index} className="col-md-4">
            <Step className="d-flex flex-column justify-content-start align-items-center my-auto h-100 p-4">
              <Icon image={step.icon} alt="" />
              <ParseContent content={step.description} className="mt-5" />
            </Step>
          </div>
        ))}
      </div>
    </StyledThanks>
  )
}

export default ThanksVacancy
